<template>
  <div class="unit u1">
    <div class="bx_tit">
      <div>
        <h3>{{ title }}</h3>
        <p class="desc">{{ description }}</p>
      </div>
    </div>
    <div class="bx_search mt10">
      <input type="text" v-model="bizNo" class="ipt" :placeholder="placeholder" @keyup.enter="fnSearch" />
      <button class="btn_ico b_search" @click="fnSearch">검색버튼</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    serviceReady: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      bizNo: null,
    };
  },
  methods: {
    fnSearch() {
      this.$userButtonLog(this.title+'조회');
      if (!this.serviceReady) {
        this.$fnAlert('서비스 준비중입니다.');
      } else {
        this.fnOpenPatentReport();
      }
    },
    fnOpenPatentReport() {
      const validResult = this.$fnValidateBizNo(this.bizNo);
      if (validResult.success) {
        this.$axios.$get(`/apiCmnAo/report/outline?corpType=U&bizNo=${this.bizNo}`).then(({ success, contents, error }) => {
          if (success) {
            if (contents.corp) {
              this.$openPatentReport(this.bizNo);
            } else {
              this.$fnAlert('비상장기업의 사업자번호를 입력해주세요.');
            }
          } else {
            if (error.cd === '3001') {
              this.$fnAlert('평가에 필요한 보유특허가 없는 기업입니다.<br/>특허를 보유한 다른 기업을 검색해주세요.');
            }
          }
        });
      } else {
        this.$fnAlert(validResult.message);
      }
    },
  },
};
</script>
